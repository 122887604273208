import React from "react";
import { graphql } from "gatsby";
import Page from "../../components/Page";
import { GatsbyImage } from "gatsby-plugin-image"

export default function BloggInkomst({ data }) {
    const image = data.image.childImageSharp.gatsbyImageData;
    const title = "Tjäna pengar på fastigheter (Guide)"

    const schema = {
      "@context": "https://schema.org/",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "Vilka är de olika typerna av fastigheter?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Det finns två huvudtyper av fastigheter: bostäder och kommersiella fastigheter."
          }
        },
        {
          "@type": "Question",
          "name": "Vad är ett hypotekslån?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Ett hypotekslån är ett lån som du tar för att finansiera köpet av en fastighet. Fastigheten används sedan som säkerhet för lånet, vilket innebär att banken kan kräva in fastigheten om du inte betalar tillbaka lånet."
          }
        },
      ]
    }

    return (
        <>
            <Page title={title} image={image?.images.fallback.src} description="Fastigheter kan vara en väldigt bra investering, eftersom det är en fysisk tillgång som du kan se och röra vid." schema={schema}>
                <div className="w-full py-3 bg-gray-100"><p className="flex items-center px-4 sm:px-8 lg:px-16 xl:px-20 justify-between mx-auto max-w-screen-xl mx-auto">Inlägget innehåller reklam genom annonslänkar.</p></div>
                <div className="container px-5 py-6 mx-auto">
                    <article className="blogPost">
                        {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="blogga" />}
                        <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">{title}</h1>
                        <p>Fastigheter kan vara en väldigt bra investering, eftersom det är en fysisk tillgång som du kan se och röra vid. En fastighet tenderar dessutom att öka i värde med tiden, och det går att generera passiva inkomster genom att hyra ut den.</p>
                        <p>Det är dock inte alltid lätt att <strong>tjäna pengar på fastigheter</strong>, så i denna artikeln kommer vi gå igenom några av de vanligaste sätten att göra det på.</p>
                        <h2 id="hur-kan-man-tj-na-pengar-p-fastigheter-">Hur kan man tjäna pengar på fastigheter?</h2>
                        <p>Det finns flera olika sätt som man kan tjäna pengar för att <strong>bli rik på fastigheter</strong>. Du kan investera i fastighetsbolag, låna ut pengar till fastighetsbolag via exempelvis Kameo eller köpa och hyra ut själv.</p>
                        <p>Alla dessa sätt har sina fördelar och nackdelar och det är därför viktigt att förstå varje enskilt alternativ innan man fattar ett beslut.</p>
                        <p>Genom att <strong>investera i fastighetsbolag</strong> kommer du överlåta förvaltningen till någon annan. Detta kan vara en bra idé om du inte har tid eller lust att sköta den dagliga driften av en hyresfastighet, men samtidigt vill vara ägare i ett fastighetsbolag.</p>
                        <p>Du kan också <strong>låna ut pengar till fastighetsbolag</strong> via tjänster såsom <a href="https://click.adrecord.com/?c=44861&amp;p=1204" rel="nofollow" target="_blank">Kameo</a> som sysslar med crowdfunding. Då lånar du ut dina pengar till ett fastighetsbolag och får en ränta som brukar ligga på omkring 7-10%.</p>
                        <p>Det sista alternativet är att <strong>köpa en fastighet</strong> och hyra ut den själv. Detta är ett mer praktiskt alternativ som vanligtvis kräver att du är mer involverad i den dagliga driften av fastigheten. Men det kan också vara en mer givande erfarenhet med större vinster.</p>
                        <p>Nedan kommer vi gå de olika sätten var försäg mer ingående.</p>
                        <h2 id="hur-fungerar-det-att-investera-i-fastighetsbolag-">Hur fungerar det att investera i fastighetsbolag?</h2>
                        <p>På börsen finns det många olika fastighetsbolag som du kan investera i. Några exempel på fastighetsbolag är SBB, Castellum, Hufvudstaden och Wallenstam.</p>
                        <p>Att <strong>investera i fastighetsbolag</strong> är ett sätt att bli fastighetsägare utan att själv behöva köpa eller förvalta någon fysisk fastighet. När du investerar i ett fastighetsbolag köper du aktier i bolaget. Detta gör dig till delägare i företaget och du kommer få ta del av <strong>utdelningen</strong> som bolaget delar ut.</p>
                        <p>Om fastighetsbolaget går bra kommer även värdet på dina aktier att stiga vilket gör att du kan få en <strong>fin avkastning</strong> på din investeringar. Men om företaget går dåligt kommer dina aktier att minska i värde, vilket kan göra att du inte får tillbaka hela ditt investerade kapital.</p>
                        <p>Ett sätt för att minska risken i sina investeringar är att använda sig av riskspridning genom att investera i flera olika fastighetsbolag. Det är också bra att investera i fastighetsbolag som inriktar sig på olika typer av fastigheter såsom både bostäder och kommersiella fastigheter.</p>
                        <h2 id="-hur-fungerar-det-att-l-na-ut-pengar-till-fastighetsbolag-">Hur fungerar det att låna ut pengar till fastighetsbolag?</h2>
                        <p>Kameo är en svensk crowdfundingplattform som gör det möjligt för privatpersoner att låna ut pengar till fastighetsbolag.</p>
                        <p>Räntan på dessa lån ligger vanligtvis runt <strong>7-10 procent</strong> och lånet har vanligtvis en avbetalningstid på ett år.</p>
                        <p>När du lånar ut pengar till ett fastighetsföretag via Kameo används dina pengar för att finansiera företagets verksamhet. Företaget betalar sedan tillbaka en ränta varje månad, och i slutet av avbetalningstiden betalar de även av hela lånet.</p>
                        <p>Risken som du tar när du investerar via Kameo är att företagen kan <strong>gå i konkurs</strong>, vilket gör att det finns en risk att du inte får tillbaka hela ditt investerade kapital.</p>
                        <p>Detta är däremot rätt så ovanligt och jag har investerat via Kameo i snart 5 år och endast upplevt en gång, och i det fallet fick jag trots allt tillbaka hela mitt investerade kapital eftersom de hade suttit en annan fastighet i pant för lånet.</p>
                        <p><a href="https://click.adrecord.com/?c=44861&amp;p=1204" rel="nofollow" target="_blank">Här kan du öppna upp ett konto på Kameo!</a></p>
                        <h2 id="hur-fungerar-det-att-hyra-ut-fastigheter-">Hur fungerar det att hyra ut fastigheter?</h2>
                        <p>Det tredje sättet att tjäna pengar på fastigheter är genom att du köper en fastighet och sedan hyr ut den själv till andra människor.</p>
                        <p>Det här alternativet är mer <strong>praktiskt</strong> eftersom du måste förvalta fastigheten själv, men det kan också vara mer givande ekonomiskt.</p>
                        <p>Detta beror på att du kommer kunna ta del av värdeökningen av fastigheten samtidigt som du får en passiv inkomst varje månad när din hyresvärd betalar hyran.</p>
                        <p>När du köper en fastighet för att hyra ut lägger du vanligtvis ner en handpenning och finansierar sedan resten av köpeskillingen med ett hypotekslån. Pengarna som du får in från hyran kan du senare använda för att betala av lånet eller så kan använda det för andra investeringar.</p>
                        <p>Nackdelen med detta alternativet är däremot att fastigheten kan minska i värde på kort sikt, vilket gör det till en mer långsiktig strategi. Du kommer också stå för kostnaderna om något skulle gå sönder i fastigheten t.ex. pannan eller ett handtag.</p>
                        <p>För att sänka risken i din uthyrning kan du även använda en tjänst såsom <a href="https://on.samtrygg.se/t/t?a=1075011258&as=1583635739&t=2&tk=1" rel="nofollow" target="_blank">Samtrygg</a>, där du dessutom kan skapa annonser för att hyra ut en boende.</p>
                        <h2 id="-r-det-l-nsamt-att-ga-egna-fastigheter-">Är det lönsamt att äga egna fastigheter?</h2>
                        <p>Ja, historiskt sätt har det varit det är mycket <strong>lönsamt</strong> att äga sin egen fastighet.</p>
                        <p>På lång sikt har fastighetsvärdena alltid ökat, vilket ger dig en fin avkastning på din investering. Det finns också många skattefördelar med att äga en egen fastighet vilket du kommer kunna dra nytta av.</p>
                        <p>Om du hyr ut en fastighet kommer du dessutom kunna få en <strong>fin passiv inkomst</strong> varje månad från hyran.</p>
                        <p>Nackdelen med att äga sin egen fastighet är att det kan vara ganska dyrt att köpa en fastighet, särskilt om du <strong>inte har kapitalet</strong>. En annan nackdel är att du kan komma behöva hantera jobbiga hyresgäster som hyr din fastighet.</p>
                        <p>Slutligen ska du komma ihåg att värdet på din fastighet också kan sjunka på kort sikt, så det är viktigt att tänka på detta innan du investerar.</p>
                        <h2 id="-vilket-r-det-b-sta-s-ttet-att-tj-na-pengar-p-fastigheter-">Vilket är det bästa sättet att tjäna pengar på fastigheter?</h2>
                        <p>Vad som är det bästa sättet att tjäna pengar på fastigheter beror <strong>dina individuella omständigheter.</strong> Men om du har kapitalet är det oftast det ekonomiskt mest lönsamma alternativet att köpa en fastighet och sedan hyra ut den.</p>
                        <p>Enda nackdelen med detta är att du kan komma behöva handskas med jobbiga hyresgäster, men samtidigt kan du anställa någon som hade kunnat göra detta åt dig.</p>
                        <p>Om du inte har kapitalet för att köpa en fastighet direkt är det ett bra sätt att tjäna pengar på fastigheter att låna ut pengar till fastighetsbolag via <strong>crowdfundingplattformar</strong> som <a href="https://click.adrecord.com/?c=44861&amp;p=1204" rel="nofollow" target="_blank">Kameo</a> om du är ute efter den passiva inkomsten.</p>
                        <p>Att investera i fastigheter genom aktier kommer också alltid vara ett bra alternativ eftersom sektorn historiskt sett alltid har ökat i värde.</p>
                        <p>Oavsett vilken väg du väljer att gå, se till att göra din forskning och tänk alltid långsiktigt när du investerar i fastigheter.</p>
                        <p>Kom ihåg att alla typer av tillgångar, oavsett om det är fastigheter, aktier eller något annat alltid kan minska i värde och du kan förlora delar eller hela ditt kapital.</p>
                        <h2 id="andra-vanliga-fr-gor-om-fastigheter">Andra vanliga frågor om fastigheter</h2>
                        <p>Nedan går vi igenom några andra vanliga frågor om fastigheter.</p>
                        <h3 id="vilka-r-de-olika-typerna-av-fastigheter-">Vilka är de olika typerna av fastigheter?</h3>
                        <p>Det finns två huvudtyper av fastigheter: bostäder och kommersiella fastigheter.</p>
                        <p>Bostadsfastigheter omfattar hus, lägenheter, bostadsrätter och radhus. Medan kommersiella fastigheter omfattar kontorsbyggnader, lagerlokaler, butikslokaler och hotell.</p>
                        <h3 id="vad-r-ett-hypoteksl-n-">Vad är ett hypotekslån?</h3>
                        <p>Ett hypotekslån är ett lån som du tar för att finansiera köpet av en fastighet. Fastigheten används sedan som säkerhet för lånet, vilket innebär att banken kan kräva in fastigheten om du inte betalar tillbaka lånet.</p>
                        <br />
                        <br />
                    </article>
                </div>
            </Page>
        </>
    );
}

export const query = graphql`
  query {
    image: file(relativePath: { eq: "houses.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
